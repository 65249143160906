import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import ContactField from '../ContactUs/ContactField';
import OptOut from '../Forms/OptOut';

const FleetSolutionsForm = ({ affinityName }) => {
  const [state, setState] = useState({
    fullName: '',
    email: '',
    telephone: '',
    message: '',
    authenticity_token: '',
    disabled: false,
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    setState({
      ...state,
      disabled: true,
      authenticity_token: document.querySelector('[name=csrf-token]').content,
    });
    formRef.current.submit();
  };

  return (
    <form
      className="c-form-contact-footer bootstrap-form"
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action=""
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="affinity_name" value={affinityName} />
      <div className="row">
        <div className="col-12 col-xl-6">
          <ContactField
            labelText="Full name"
            id="contact_name"
            type="text"
            onChange={(event) => setState({ ...state, fullName: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.contact_name}
            value={state.fullName}
          />

          <ContactField
            labelText="Email"
            id="contact_email"
            type="email"
            onChange={(event) => setState({ ...state, email: event.target.value })}
            ref={register({
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            hasError={!!errors.contact_email}
            value={state.email}
          />

          <ContactField
            labelText="Telephone"
            id="contact_telephone"
            type="tel"
            onChange={(event) => setState({ ...state, telephone: event.target.value })}
            ref={register({
              required: true,
              validate: (value) => isValidNumber(value, 'GB'),
            })}
            hasError={!!errors.contact_telephone}
            value={state.telephone}
          />
        </div>
        <div className="col-12 col-xl-6">
          <ContactField
            labelText="Message"
            rows={8}
            id="contact_message"
            onChange={(event) => setState({ ...state, message: event.target.value })}
            ref={register({ required: false })}
            hasError={!!errors.contact_message}
            value={state.message}
            variant="textarea"
            style={{ lineHeight: 1.3 }}
          />

          <div className="c-form-contact-footer__form-group">
            <button className="c-form-contact-footer__btn" type="submit" disabled={state.disabled}>
              {state.disabled ? 'Sending...' : 'Send message'}
            </button>
          </div>
        </div>
      </div>
      <OptOut on_dark={false} ref={register({ required: false })} />
    </form>
  );
};

FleetSolutionsForm.propTypes = {
  affinityName: PropTypes.string.isRequired,
};

export default FleetSolutionsForm;
