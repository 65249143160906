import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import FormField from '../Forms/FormField';

import { emailValidationRegex } from '../Forms/utils';

const CarFinanceForm = ({ postURL, channelID }) => {
  const [state, setState] = useState({
    fullName: '',
    email: '',
    telephone: '',
    comments: '',
    authenticity_token: '',
    disabled: false,
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    setState({
      ...state,
      disabled: true,
      authenticity_token: document.querySelector('[name=csrf-token]').content,
    });
    formRef.current.submit();
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action={postURL}
      method="post"
      className="c-callback-form"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />
      <div className="row">
        <FormField
          labelText="Full name *"
          placeholder="Full name"
          id="contact_name"
          type="text"
          onChange={(event) => setState({ ...state, fullName: event.target.value })}
          ref={register({ required: true })}
          hasError={!!errors.contact_name}
          value={state.fullName}
        />

        <FormField
          labelText="Telephone *"
          placeholder="Telephone"
          id="contact_telephone"
          type="tel"
          onChange={(event) => setState({ ...state, telephone: event.target.value })}
          ref={register({
            required: true,
            validate: (value) => isValidNumber(value, 'GB'),
          })}
          hasError={!!errors.contact_telephone}
          value={state.telephone}
        />
      </div>

      <div className="row">
        <FormField
          labelText="Email *"
          placeholder="Email"
          id="contact_email"
          type="email"
          onChange={(event) => setState({ ...state, email: event.target.value })}
          ref={register({
            required: true,
            pattern: emailValidationRegex,
          })}
          hasError={!!errors.contact_email}
          value={state.email}
        />

        <FormField
          labelText="Comments"
          id="contact_comments"
          placeholder="Comments"
          onChange={(event) => setState({ ...state, comments: event.target.value })}
          value={state.comments}
          variant="textarea"
        />
      </div>

      <div className="row">
        <div className="form-group col-12">
          <button className="b-btn b-btn-green b-btn-block b-btn-shadowless" type="submit" disabled={state.disabled}>
            {state.disabled ? 'Sending...' : 'Send my enquiry'}
          </button>
        </div>
      </div>
    </form>
  );
};

CarFinanceForm.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
};

export default CarFinanceForm;
