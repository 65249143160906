import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ButtonList = ({ options, onClick }) => {
  return (
    <div className="c-survey-question__buttons button-holder button-submit" role="group">
      {options &&
        options.map((option) => {
          const classes = classNames({
            'c-survey-question__button': true,
            'is-active': option.active,
          });

          return (
            <button className={classes} onClick={onClick} key={option.text} disabled={option.disabled}>
              {option.text}
            </button>
          );
        })}
    </div>
  );
};

ButtonList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      active: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onClick: PropTypes.func,
};

ButtonList.defaultProps = {
  onClick: null,
};

export default ButtonList;
