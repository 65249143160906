import React, { useState } from 'react';
import Cookies from 'js-cookie';

const CookieBanner = () => {
  const [state, setState] = useState({
    isOpen: Cookies.get('accept_cookies') !== 'false',
  });

  const handleClose = (event) => {
    event.preventDefault();

    Cookies.set('accept_cookies', 'true', { expires: 365, sameSite: 'Lax', path: '/' });
    setState({ ...state, isOpen: true });
  };

  return (
    <div className={`c-cookies${state.isOpen ? ' is-accepted' : ''}`}>
      <div className="l-container">
        <h4 className="c-cookies__heading">Our website uses cookies</h4>
        <div className="c-cookies__content">
          <p className="c-cookies__text">
            Our website uses cookies to distinguish you from other users of our website. This helps us to provide you
            with a good experience when you browse our website and also allows us to improve our site. For detailed
            information on the cookies we use and the purposes for which we use them see our&nbsp;
            <a className="c-cookies__link" href="/privacy-policy">
              Privacy policy
            </a>
            .
          </p>
          <p className="c-cookies__actions">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="c-cookies__accept" href="#" onClick={handleClose}>
              Accept and close
            </a>
            <a className="c-cookies__link" href="/privacy-policy">
              Learn more
              <span className="sr-only"> about our privacy policy</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
