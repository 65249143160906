import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import ContactUs from './ContactUs';

const ContactUsFooter = ({ postURL, channelID, heading, name, telephone, email, address }) => {
  return (
    <div className="g-footer-contact">
      <div className="l-container">
        <h2 className="c-footer-contact__heading">{heading}</h2>

        <div className="row">
          <ContactUs postURL={postURL} channelID={channelID} on_dark />

          <div className="c-footer-address-group">
            <address className="c-address">
              <h3 className="c-address__heading">{name}</h3>
              {address.map((item) => (
                <span key={item}>{item}</span>
              ))}
              <a className="c-address__phone" href={`tel:${telephone}`}>
                {telephone}
              </a>
              {email && (
                <a className="c-address__phone" href={`mailto:${email}`}>
                  {email}
                </a>
              )}
            </address>
          </div>
        </div>
      </div>
    </div>
  );
};

ContactUsFooter.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
  heading: PropTypes.string,
  name: PropTypes.string,
  telephone: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.arrayOf(PropTypes.string),
};

ContactUsFooter.defaultProps = {
  heading: 'Contact us',
  name: 'Head office',
  telephone: '0118 920 5130',
  address: ['Pacific House', 'Imperial Way', 'Reading', 'RG2 0TD'],
  email: '',
};

export default ContactUsFooter;
