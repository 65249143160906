import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ContactField = React.forwardRef(
  (
    { type, id, labelText, onChange, value, hasError, errorMessage, rows, variant, options, style, isElectric },
    ref,
  ) => {
    const [isValid, setIsValid] = useState(false);

    const handleBlur = () => {
      if (!hasError) {
        setIsValid(true);
      }
    };

    const inputClasses = classNames({
      'b-form-control-modern': true,
      'b-form-control-modern--ev': isElectric,
      'is-invalid': hasError,
      'is-valid': !hasError && isValid,
    });

    const selectClasses = classNames({
      'b-form-select-modern': true,
      'b-form-select-modern--ev': isElectric,
      'is-invalid': hasError,
      'is-valid': !hasError && isValid,
    });

    const showErrorMessage = hasError ? 'visible' : 'invisible';

    const Tag = variant || 'input';

    return (
      <div className="b-form-group-modern">
        {variant !== 'select' ? (
          <Tag
            className={inputClasses}
            type={type}
            name={id}
            id={id}
            value={value}
            onChange={onChange}
            ref={ref}
            aria-invalid={hasError ? true : undefined}
            onBlur={handleBlur}
            rows={rows}
            style={style}
            placeholder=""
          />
        ) : (
          <select
            className={selectClasses}
            id={id}
            name={id}
            value={value}
            onChange={onChange}
            ref={ref}
            aria-invalid={hasError ? true : undefined}
            onBlur={handleBlur}
          >
            {options &&
              options.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.text}
                </option>
              ))}
          </select>
        )}
        <label className="b-form-label" htmlFor={id}>
          {labelText}
        </label>
        <div className={`invalid-feedback ${showErrorMessage}`}>{errorMessage}</div>
      </div>
    );
  },
);

ContactField.defaultProps = {
  errorMessage: 'Please check and enter it again.',
  type: '',
  hasError: false,
  variant: 'input',
  rows: '1',
  isElectric: false,
  style: null,
  options: [],
};

ContactField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  rows: PropTypes.string,
  isElectric: PropTypes.bool,
  style: PropTypes.objectOf(),
  variant: PropTypes.oneOf(['input', 'textarea', 'select']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

export default ContactField;
