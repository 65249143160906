import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import ContactField from './ContactField';
import OptOut from '../Forms/OptOut';
import Recaptcha from '../Recaptcha/Recaptcha';

import { pushData } from '../../ga/form_ga_data';

const ContactUs = ({ postURL, channelID, recaptcha, fleet_size, style, on_dark, leadType }) => {
  const queries = [
    {
      text: 'Select query',
      value: '',
      options: [],
      email: '',
    },
    {
      text: 'General enquiries',
      value: 'general_enquiries',
      options: [],
      email: 'enquiries@selectcarleasing.co.uk',
    },
    {
      text: 'For new enquiries',
      value: 'new_enquiries',
      options: [],
      email: '',
    },
    {
      text: 'For existing orders',
      value: 'existing_orders',
      options: [
        {
          text: 'Proof + Finance agreements related queries',
          value: 'proof_and_finance',
          email: 'documents@selectcarleasing.co.uk',
        },
        {
          text: 'Vehicle delivery related queries',
          value: 'vehicle_delivery',
          email: 'deliveries@selectcarleasing.co.uk',
        },
        {
          text: 'Payments related queries',
          value: 'payments',
          email: 'invoicing@selectcarleasing.co.uk',
        },
        {
          text: 'All other enquiries',
          value: 'other_enquiries',
          email: 'customerservices@selectcarleasing.co.uk',
        },
      ],
      email: '',
    },
    {
      text: 'Already in your lease vehicle?',
      value: 'existing_contracts',
      options: [
        {
          text: 'Contract amendments/Lease extensions',
          value: 'contract_amendments',
          email: 'endofcontracts@selectcarleasing.co.uk',
        },
        {
          text: 'General queries',
          value: 'contract_general_queries',
          email: 'inlifecontacts@selectcarleasing.co.uk',
        },
      ],
      email: '',
    },
    {
      text: 'For customer portal & document related queries',
      value: 'customer_portal',
      options: [],
      email: 'crmupdates@selectcarleasing.co.uk',
    },
    {
      text: 'For select protect, alloy, care & GAP insurance queries',
      value: 'select_protect',
      options: [],
      email: 'selectcare@selectcarleasing.co.uk',
    },
    {
      text: 'For maintainance enquiries',
      value: 'maintainance_enquiries',
      options: [],
      email: 'maintenance@selectcarleasing.co.uk',
    },
  ];

  const placeholder = {
    text: 'Select option',
    value: '',
    options: [],
    email: '',
  };

  const [state, setState] = useState({
    query: queries[0],
    option: placeholder,
    department_email: '',
    fullName: '',
    email: '',
    telephone: '',
    car_registration: '',
    fleetSize: '',
    message: '',
    authenticity_token: '',
    completeRecaptcha: false,
    disabled: false,
  });

  const handleQueryChange = (value) => {
    const query = queries.find((q) => q.value === value);

    setState({
      ...state,
      query,
      option: placeholder,
      department_email: query.email,
    });
  };

  const handleOptionChange = (value) => {
    let option = state.query.options.find((o) => o.value === value);

    option = option || placeholder;

    setState({ ...state, option, department_email: option.email });
  };

  const getQueryOptions = () => {
    const options = state.query.options.map(({ text, value }) => ({
      value,
      text,
    }));

    return [placeholder, ...options];
  };

  const { register, handleSubmit, errors, getValues } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    if (recaptcha) {
      if (recaptchaRef.current.value !== '') {
        setState({
          ...state,
          disabled: true,
          authenticity_token: document.querySelector('[name=csrf-token]').content,
        });
        formRef.current.submit();
      } else {
        setState({ ...state, completeRecaptcha: true });
      }
    } else {
      setState({
        ...state,
        disabled: true,
        authenticity_token: document.querySelector('[name=csrf-token]').content,
      });
      formRef.current.submit();
    }

    pushData({
      form_name: leadType,
      signup: !getValues('contact_opt_out'),
      user_data: {
        email: getValues('contact_email'),
        phone_number: getValues('contact_telephone'),
      },
    });
  };

  const isComplaintForm = useMemo(() => postURL === '/complaint-form', [postURL]);

  return (
    <form
      className="c-form-contact-footer bootstrap-form"
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action={postURL}
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />
      <input type="hidden" name="department_email" value={state.department_email} />
      <div className="row">
        {!isComplaintForm && (
          <div className="col-12">
            <ContactField
              labelText="Please select your relevent query"
              id="query"
              variant="select"
              onChange={(event) => handleQueryChange(event.target.value)}
              ref={register({ required: true })}
              hasError={!!errors.query}
              value={state.query.value}
              options={queries.map(({ text, value }) => ({
                value,
                text,
              }))}
            />
          </div>
        )}
        {!!state.query.options.length && (
          <div className="col-12">
            <ContactField
              labelText="Select option"
              id="option"
              variant="select"
              onChange={(event) => handleOptionChange(event.target.value)}
              ref={register({ required: true })}
              hasError={!!errors.option}
              value={state.option.value}
              options={getQueryOptions()}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12 col-xl-6">
          <ContactField
            labelText="Full name"
            id="contact_name"
            type="text"
            onChange={(event) => setState({ ...state, fullName: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.contact_name}
            value={state.fullName}
          />

          <ContactField
            labelText="Email"
            id="contact_email"
            type="email"
            onChange={(event) => setState({ ...state, email: event.target.value })}
            ref={register({
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            hasError={!!errors.contact_email}
            value={state.email}
          />

          <ContactField
            labelText="Telephone"
            id="contact_telephone"
            type="tel"
            onChange={(event) => setState({ ...state, telephone: event.target.value })}
            ref={register({
              required: true,
              validate: (value) => isValidNumber(value, 'GB'),
            })}
            hasError={!!errors.contact_telephone}
            value={state.telephone}
          />

          {state.query.value === 'existing_contracts' && state.option.value === 'contract_amendments' && (
            <ContactField
              labelText="Car Registration (VRM)"
              id="contact_car_registration"
              type="text"
              onChange={(event) => setState({ ...state, car_registration: event.target.value })}
              ref={register({ required: true, maxLength: 8, minLength: 2 })}
              hasError={!!errors.contact_car_registration}
              value={state.car_registration}
              errorMessage="Please enter a valid registration plate."
            />
          )}
        </div>
        <div className="col-12 col-xl-6">
          {fleet_size && (
            <ContactField
              labelText="Fleet size"
              id="contact_fleet_size"
              onChange={(event) => setState({ ...state, fleetSize: event.target.value })}
              ref={register({ required: true })}
              hasError={!!errors.contact_fleet_size}
              value={state.fleetSize}
              variant="select"
              options={[
                { value: '', text: 'Select fleet size' },
                { value: '1to9', text: '1-9' },
                { value: '10to49', text: '10-49' },
                { value: '50', text: '50+' },
              ]}
            />
          )}

          <ContactField
            labelText="Message"
            rows="5"
            id="contact_message"
            onChange={(event) => setState({ ...state, message: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.contact_message}
            value={state.message}
            errorMessage="Please send us a message!"
            variant="textarea"
            style={style}
          />

          {recaptcha && (
            <>
              <Recaptcha ref={recaptchaRef} />

              {state.completeRecaptcha && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  Please complete reCaptcha
                </div>
              )}
            </>
          )}

          <div className="c-form-contact-footer__form-group">
            <button className="c-form-contact-footer__btn" type="submit" disabled={state.disabled}>
              {state.disabled ? 'Sending...' : 'Send message'}
            </button>
          </div>
        </div>
      </div>
      {!isComplaintForm && <OptOut on_dark={on_dark} ref={register({ required: false })} />}
    </form>
  );
};

ContactUs.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
  recaptcha: PropTypes.bool,
  fleet_size: PropTypes.bool,
  on_dark: PropTypes.bool,
  style: PropTypes.objectOf(),
  leadType: PropTypes.string,
};

ContactUs.defaultProps = {
  recaptcha: false,
  fleet_size: false,
  on_dark: false,
  style: null,
  leadType: '',
};

export default ContactUs;
