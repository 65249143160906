import React from 'react';
import PropTypes from 'prop-types';

const ArticleCard = ({ href, banner, date, heading, description }) => {
  return (
    <article className="c-news-card">
      <a className="c-news-card__link" href={`news/article/${href}`}>
        <div className="c-news-card__banner">
          {banner && <img className="c-news-card__image" src={banner} alt="" />}
          <div className="c-news-card__hover">
            <span className="c-news-card__read-more">Read More</span>
          </div>
        </div>

        <div className="c-news-card__content">
          <p className="c-news-card__date">{date.split('T')[0]}</p>
          <h2 className="c-news-card__title">{heading}</h2>
          <p className="c-news-card__excerpt">{description}</p>
        </div>
      </a>
    </article>
  );
};

ArticleCard.propTypes = {
  href: PropTypes.string.isRequired,
  banner: PropTypes.string,
  date: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
};

ArticleCard.defaultProps = {
  banner: '',
  description: '',
};

export default ArticleCard;
