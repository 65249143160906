import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import './CheckList.scss';

const CheckListItem = ({ onClick, name, disabled, id, is_checked }) => {
  const handleClick = (event) => {
    onClick(event);
  };

  return (
    <li className="checklist__list-item">
      <input
        className="checklist__input"
        type="checkbox"
        id={`checkbox-${id}`}
        name={`checkbox-${id}`}
        onClick={handleClick}
        disabled={disabled}
        value={is_checked}
        defaultChecked={is_checked}
      />
      <label className="checklist__label" htmlFor={`checkbox-${id}`}>
        {name}
      </label>
    </li>
  );
};

CheckListItem.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  is_checked: PropTypes.bool,
};

CheckListItem.defaultProps = {
  disabled: false,
  onClick: () => {},
  is_checked: false,
};

export default CheckListItem;
