import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import './CheckList.scss';

const CheckList = ({ children }) => {
  return (
    <div className="checklist" role="group">
      <ul className="checklist__list">{children}</ul>
    </div>
  );
};

CheckList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CheckList;
