import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Accordion = ({ id, buttonText, children, isOpen, onClick }) => {
  const [height, setHeight] = useState(isOpen ? 'auto' : 0);
  const [speed, setSpeed] = useState(0);
  const [visibility, setVisibility] = useState('hidden');

  const content = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const contentHeight = content.current.scrollHeight;

      const time = contentHeight / 1000;

      let animation = time;
      if (time < 0.3) {
        animation = 0.3;
      } else if (time > 1) {
        animation = 1;
      }

      setVisibility('visible');

      setHeight(contentHeight);
      setSpeed(animation);
    } else {
      setHeight(0);

      setTimeout(() => {
        setVisibility('hidden');
      }, speed * 1000);
    }
  }, [isOpen, speed, children]);

  return (
    <>
      <div className="b-form-group">
        <button
          className="g-order__reveal reveal info"
          aria-controls={`${id}-content`}
          aria-expanded={isOpen}
          onClick={onClick}
          style={{ width: '100%', textAlign: 'left' }}
        >
          {buttonText}
          <span className={`${!isOpen ? 'g-order__plus-icon' : 'g-order__minus-icon'}`} />
        </button>
      </div>

      <section
        style={{
          transition: `height ${speed}s ease-in-out`,
          height,
          overflow: 'hidden',
        }}
        id={`${id}-content`}
        aria-hidden={!isOpen}
      >
        <div ref={content} style={{ overflow: 'auto', visibility }}>
          {children}
        </div>
      </section>
    </>
  );
};

Accordion.propTypes = {
  id: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Accordion;
