import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import ContactField from './ContactField';
import Recaptcha from '../Recaptcha/Recaptcha';

import { pushData } from '../../ga/form_ga_data';

const CmsContactUs = ({ postURL, channelID, recaptcha, formType, buttonClass, theme, leadType }) => {
  const queries = [
    { text: 'Select query', value: '', options: [], email: '' },
    {
      text: 'General enquiries',
      value: 'general_enquiries',
      options: [],
      email: 'enquiries@selectcarleasing.co.uk',
    },
    {
      text: 'For new enquiries',
      value: 'new_enquiries',
      options: [],
      email: '',
    },
    {
      text: 'For existing orders',
      value: 'existing_orders',
      options: [
        {
          text: 'Proof + Finance agreements related queries',
          value: 'proof_and_finance',
          email: 'documents@selectcarleasing.co.uk',
        },
        {
          text: 'Vehicle delivery related queries',
          value: 'vehicle_delivery',
          email: 'deliveries@selectcarleasing.co.uk',
        },
        {
          text: 'Payments related queries',
          value: 'payments',
          email: 'invoicing@selectcarleasing.co.uk',
        },
        {
          text: 'All other enquiries',
          value: 'other_enquiries',
          email: 'customerservices@selectcarleasing.co.uk',
        },
      ],
      email: '',
    },
    {
      text: 'Already in your lease vehicle?',
      value: 'existing_contracts',
      options: [
        {
          text: 'Contract amendments/Lease extensions',
          value: 'contract_amendments',
          email: 'endofcontracts@selectcarleasing.co.uk',
        },
        {
          text: 'General queries',
          value: 'contract_general_queries',
          email: 'inlifecontacts@selectcarleasing.co.uk',
        },
      ],
      email: '',
    },
    {
      text: 'For customer portal & document related queries',
      value: 'customer_portal',
      options: [],
      email: 'crmupdates@selectcarleasing.co.uk',
    },
    {
      text: 'For select protect, alloy, care & GAP insurance queries',
      value: 'select_protect',
      options: [],
      email: 'selectcare@selectcarleasing.co.uk',
    },
    {
      text: 'For maintainance enquiries',
      value: 'maintainance_enquiries',
      options: [],
      email: 'maintenance@selectcarleasing.co.uk',
    },
  ];

  const placeholder = {
    text: 'Select option',
    value: '',
    options: [],
    email: '',
  };

  const [state, setState] = useState({
    query: queries[0],
    option: placeholder,
    department_email: '',
    fullName: '',
    email: '',
    telephone: '',
    car_registration: '',
    fleetSize: '',
    message: '',
    authenticity_token: '',
    completeRecaptcha: false,
    disabled: false,
  });

  const handleQueryChange = (value) => {
    const query = queries.find((q) => q.value === value);

    setState({
      ...state,
      query,
      option: placeholder,
      department_email: query.email,
    });
  };

  const optOutId = `contact_opt_out_${Date.now()}`;

  const isElectric = theme === 'electric';

  const handleOptionChange = (value) => {
    let option = state.query.options.find((o) => o.value === value);

    option = option || placeholder;

    setState({ ...state, option, department_email: option.email });
  };

  const getQueryOptions = () => {
    const options = state.query.options.map(({ text, value }) => ({
      value,
      text,
    }));

    return [placeholder, ...options];
  };

  const { register, handleSubmit, errors, getValues } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    if (recaptcha) {
      if (recaptchaRef.current.value !== '') {
        setState({
          ...state,
          disabled: true,
          authenticity_token: document.querySelector('[name=csrf-token]').content,
        });
        formRef.current.submit();
      } else {
        setState({ ...state, completeRecaptcha: true });
      }
    } else {
      setState({
        ...state,
        disabled: true,
        authenticity_token: document.querySelector('[name=csrf-token]').content,
      });
      formRef.current.submit();
    }

    pushData({
      form_name: leadType,
      signup: !getValues('contact_opt_out'),
      user_data: {
        email: getValues('contact_email'),
        phone_number: getValues('contact_telephone'),
      },
    });
  };

  return (
    <form
      className="g-contact-form__content"
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action={postURL}
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />
      <input type="hidden" name="department_email" value={state.department_email} />
      {formType === 'query' && (
        <>
          <ContactField
            id="query"
            variant="select"
            onChange={(event) => handleQueryChange(event.target.value)}
            ref={register({ required: true })}
            hasError={!!errors.query}
            value={state.query.value}
            isElectric={isElectric}
            options={queries.map(({ text, value }) => ({
              value,
              text,
            }))}
          />
          {!!state.query.options.length && (
            <ContactField
              id="option"
              variant="select"
              onChange={(event) => handleOptionChange(event.target.value)}
              ref={register({ required: true })}
              hasError={!!errors.option}
              value={state.option.value}
              isElectric={isElectric}
              options={getQueryOptions()}
            />
          )}
        </>
      )}
      <div className="row no-gutters">
        <div className={formType === 'fleet' ? 'col-6 pr-4' : 'col-12'}>
          <ContactField
            labelText="Full name"
            id="contact_name"
            type="text"
            onChange={(event) => setState({ ...state, fullName: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.contact_name}
            isElectric={isElectric}
            value={state.fullName}
          />
        </div>
        {formType === 'fleet' && (
          <div className="col-6">
            <ContactField
              id="contact_fleet_size"
              onChange={(event) => setState({ ...state, fleetSize: event.target.value })}
              ref={register({ required: true })}
              hasError={!!errors.contact_fleet_size}
              value={state.fleetSize}
              variant="select"
              isElectric={isElectric}
              options={[
                { value: '', text: 'Select fleet size' },
                { value: '1to9', text: '1-9' },
                { value: '10to49', text: '10-49' },
                { value: '50', text: '50+' },
              ]}
            />
          </div>
        )}
      </div>
      <div className="row no-gutters">
        <div className="col-12 col-md-6 pr-md-4">
          <ContactField
            labelText="Email"
            id="contact_email"
            type="email"
            isElectric={isElectric}
            onChange={(event) => setState({ ...state, email: event.target.value })}
            ref={register({
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            hasError={!!errors.contact_email}
            value={state.email}
          />
        </div>
        <div className="col-12 col-md-6">
          <ContactField
            labelText="Telephone"
            id="contact_telephone"
            type="tel"
            isElectric={isElectric}
            onChange={(event) => setState({ ...state, telephone: event.target.value })}
            ref={register({
              required: true,
              validate: (value) => isValidNumber(value, 'GB'),
            })}
            hasError={!!errors.contact_telephone}
            value={state.telephone}
          />
        </div>
      </div>
      {state.query.value === 'existing_contracts' && state.option.value === 'contract_amendments' && (
        <ContactField
          labelText="Car Registration (VRM)"
          id="contact_car_registration"
          type="text"
          isElectric={isElectric}
          onChange={(event) => setState({ ...state, car_registration: event.target.value })}
          ref={register({ required: true, maxLength: 8, minLength: 2 })}
          hasError={!!errors.contact_car_registration}
          value={state.car_registration}
          errorMessage="Please enter a valid registration plate."
        />
      )}

      <ContactField
        labelText="Message"
        rows="5"
        id="contact_message"
        isElectric={isElectric}
        onChange={(event) => setState({ ...state, message: event.target.value })}
        ref={register({ required: false })}
        hasError={!!errors.contact_message}
        value={state.message}
        errorMessage="Please send us a message!"
        variant="textarea"
      />

      {recaptcha && (
        <>
          <Recaptcha ref={recaptchaRef} />

          {state.completeRecaptcha && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              Please complete reCaptcha
            </div>
          )}
        </>
      )}
      <div className="g-contact-form__footer">
        <div className="g-contact-form__opt-out">
          <div className="custom-checkbox custom-checkbox-xl">
            <input
              className="custom-control-input"
              type="checkbox"
              id={optOutId}
              name="contact_opt_out"
              value="true"
              ref={register({ required: false })}
            />
            <label className="custom-control-label" htmlFor={optOutId}>
              We will sometimes send you emails about our very latest offers and relevant news, in accordance with{' '}
              <a
                href="https://ico.org.uk/media/for-organisations/documents/1555/direct-marketing-guidance.pdf"
                target="_blank"
                rel="noreferrer"
              >
                ICO P&ECR
              </a>{' '}
              soft opt in rules & our{' '}
              <a href="/privacy-policy" target="_blank">
                privacy policy
              </a>
              . Simply tick the box if you do not wish to receive them.
            </label>
          </div>
        </div>

        <button className={`g-contact-form__submit ${buttonClass}`} type="submit" disabled={state.disabled}>
          {state.disabled ? 'Sending...' : 'Send message'}
        </button>
      </div>
    </form>
  );
};

CmsContactUs.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
  recaptcha: PropTypes.bool,
  formType: PropTypes.oneOf(['query', 'electric', 'vans', 'fleet']).isRequired,
  theme: PropTypes.oneOf(['default', 'electric']),
  buttonClass: PropTypes.string,
  leadType: PropTypes.string,
};

CmsContactUs.defaultProps = {
  recaptcha: false,
  leadType: '',
  buttonClass: '',
  theme: 'default',
};

export default CmsContactUs;
