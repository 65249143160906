import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import ContactUs from './ContactUs';

const ContactUsPartner = ({ postURL, channelID, fleet_size, style, leadType }) => {
  return (
    <ContactUs
      postURL={postURL}
      channelID={channelID}
      fleet_size={fleet_size}
      style={style}
      on_dark={false}
      leadType={leadType}
    />
  );
};

ContactUsPartner.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
  fleet_size: PropTypes.bool,
  style: PropTypes.objectOf(),
  leadType: PropTypes.string,
};

ContactUsPartner.defaultProps = {
  fleet_size: false,
  style: null,
  leadType: '',
};

export default ContactUsPartner;
